
  import Vue from 'vue';

  export default Vue.extend({
    created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '任务设置',
          path: 'task',
        },
      ]);
    },
  });

